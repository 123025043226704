import React, { Component } from 'react';
import { assocPath, compose, path, pathOr } from 'ramda';
import Form from '@experium/findy-form';

import '@experium/findy-form/lib/styles/index.css';

const VacancyForm = ({ domain, mutation, onSubmit }) => {
    const preset = path(['_relations', 'vacancyFormPreset'], domain);
    const fields = compose(
        // 10
        // 10.1
        assocPath(['questions', 14, 'settings', 'linkField'], path(['questions', 13, 'field'], preset)),
        assocPath(['questions', 14, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', 14, 'settings', 'linkValue'], path(['questions', 13, 'settings', 'choices', 1, 'id'], preset)),
        assocPath(['questions', 14, 'required'], true),
        // 10.2
        assocPath(['questions', 15, 'settings', 'linkField'], path(['questions', 13, 'field'], preset)),
        assocPath(['questions', 15, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', 15, 'settings', 'linkValue'], path(['questions', 13, 'settings', 'choices', 2, 'id'], preset)),
        assocPath(['questions', 15, 'required'], true),
        // 11
        // 11.1
        assocPath(['questions', 17, 'settings', 'linkField'], path(['questions', 16, 'field'], preset)),
        assocPath(['questions', 17, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', 17, 'settings', 'linkValue'], path(['questions', 16, 'settings', 'choices', 1, 'id'], preset)),
        assocPath(['questions', 17, 'required'], true),
        // 11.2
        // 11.2.1
        assocPath(['questions', 18, 'settings', 'linkField'], path(['questions', 16, 'field'], preset)),
        assocPath(['questions', 18, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', 18, 'settings', 'linkValue'], path(['questions', 16, 'settings', 'choices', 0, 'id'], preset)),
        // 11.2.1.
        assocPath(['questions', 19, 'settings', 'linkField'], path(['questions', 18, 'field'], preset)),
        assocPath(['questions', 19, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', 19, 'settings', 'linkValue'], true),
        assocPath(['questions', 19, 'required'], true),
            assocPath(['questions', 20, 'settings', 'linkField'], path(['questions', 19, 'field'], preset)),
            assocPath(['questions', 20, 'settings', 'linkType'], 'hide'),
            assocPath(['questions', 20, 'settings', 'linkValue'], path(['questions', 19, 'settings', 'choices', 2, 'id'], preset)),
            assocPath(['questions', 20, 'required'], true),
        assocPath(['questions', 21, 'settings', 'linkField'], path(['questions', 18, 'field'], preset)),
        assocPath(['questions', 21, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', 21, 'settings', 'linkValue'], true),
        assocPath(['questions', 21, 'required'], true),
        // 11.2.2
        assocPath(['questions', 22, 'settings', 'linkField'], path(['questions', 16, 'field'], preset)),
        assocPath(['questions', 22, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', 22, 'settings', 'linkValue'], path(['questions', 16, 'settings', 'choices', 0, 'id'], preset)),
        // 11.2.2.
        assocPath(['questions', 23, 'settings', 'linkField'], path(['questions', 22, 'field'], preset)),
        assocPath(['questions', 23, 'settings', 'linkType'], 'hide'),
        assocPath(['questions', 23, 'settings', 'linkValue'], true),
        assocPath(['questions', 23, 'required'], true),
    )(preset);

    return <Form
        fields={path(['questions'], fields)}
        onSubmit={onSubmit}
        opd={pathOr('', ['_relations', 'company', 'pdaSettings', 'text'], domain)}
        htmlOpdStyles={'.opd-html-form div > div { text-align: justify; }'}
        opdSettings={pathOr({}, ['_relations', 'company', 'pdaSettings'], domain)}
        allowFileExtensions={path(['_relations', 'company', 'companySettings', 'allowFileExtensions'], domain)}
        captcha={path(['_relations', 'company', 'companySettings', 'captcha', 'domains'], domain)}
        captchaOptions={path(['_relations', 'domainFormPreset', 'options'], domain)}
        company={pathOr({}, ['_relations', 'company', 'id'], domain)}
        postFileUrl={`/api/public/file/${domain.id}`}
        getFileUrl={id => `${window.location.origin}/api/file/${id}`}
        language='ru'
        languageOrigin='ru'
        pdaLanguage='ru'
        serverErrors={path(['response', 'data', 'errors'], mutation.error)}
        submitting={mutation.isLoading}
        scrollContainerClassName={'.modal-body'}
    />;
}

export default class Vacancy extends Component {
    onSubmit = (values, formProps) => {
        const domain = this.props.domain.id;

        const id = path(['id'], this.props.vacancy);

        if (id) {
            return this.props.mutation.mutate({
                vacancy: id,
                domain,
                values,
            });
        }

        this.props.mutation.mutate({ vacancy: null, domain, values });
    }

    render() {
        return (
            <div className="wrap-form">
                <VacancyForm {...this.props} onSubmit={this.onSubmit} />
            </div>
        );
    }
}
